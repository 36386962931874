import Header from './header/header.js';
import template from './dashboard.html';
import Components from './components/components.js';
import Sidebar from './sidebar/sidebar.js';
import Company from './company/company.js';
import EventHome from './home/event/eventhome.js';
import Home from './home/home.js';
import Admin from './admin/admin.js';
import Orders from './orders/orders.js';
import OrdersTickets from './ordersTickets/ordersTickets.js';
import ScanActions from './scanactions/scanactions.js';
import TicketTemplate from './template/template.js';
import MetaData from './metaData/metaData.js';
import Events from './events/events.js';
import Coupons from './coupons/coupons.js';
import Vouchers from './coupon_vouchers/vouchers.js';
import Shops from './shops/shops.js';
import Errors from './error/error.js';
import Trackers from './trackers/trackers.js';
import Collapses from './collapses/collapses.js';
import Scanners from './scanners/scanners.js';
import CheckInMonitor from './checkInMonitor/checkInMonitor';
import Exports from './exports/exports.js';
import Profile from './profile/profile.js';
import Wizard from './wizard/wizard.js';
import Import from './import/import.js';
import OfflineTickets from './offlineTickets/offlineTickets.js';
import DataAnalysis from './dataAnalysis';
import DemographicsTool from './demographicsTool/demographicsTool.js';
import Kickbacks from './kickbacks/kickbacks.js';
import Financials from './financials/financials.js';
import Marketplace from './marketplace/marketplace.js';
import './dashboard.less';
import rudderstack from "../rudderstack/rudderstack";

/**
 * @ngdoc overview
 * @name Dashboard
 * @description
 * <h1>Dashboard</h1>
 * This module holds all dashboard states. It's children have access to three view blocks. `main`, `sidebar`, and
 * `header`. It also resolves the following injectables for its children
 *
 * 1. `user:`     The authenticated user
 * 2. `events:`   Array of events of this company
 * 3. `roles:`    Array of roles the user is authenticated for
 * 4. `isAdmin:`  Boolean
 */
export default angular.module('eventix.dashboard',[
    Sidebar.module, Header.module, Components, Company, Home, EventHome,
    Admin, MetaData, Events, Shops, Errors, Orders,
    OrdersTickets, Coupons, Trackers, Collapses, Scanners,
    CheckInMonitor, Exports, Profile, Wizard, Import, ScanActions, TicketTemplate,
    OfflineTickets, DataAnalysis, Kickbacks, Financials, DemographicsTool, Marketplace, Vouchers])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard', {
            views: {
                main: {
                    controller: 'DashboardController as vm',
                    templateUrl: template
                },
                sidebar: {
                    controller: Sidebar.controller,
                    templateUrl: Sidebar.templateUrl,
                    controllerAs: 'vm'
                },
                header: {
                    controller: Header.controller,
                    templateUrl: Header.templateUrl,
                    controllerAs: 'vm'
                }
            },
            resolve: /*@ngInject*/{
                user: function(User, $q, $window, Locales, $http, $location, $stateParams) {
                    // If no code is present, redirect
                    let code = $stateParams.code;

                    // Check if eaid exists
                    let matches = $location.hash().match(new RegExp('eaid=([^&]*)'));
                    let eaid = matches ? matches[1] : null;

                    // Force auth_code_login
                    if(typeof NG_AUTH_FORCE_AUTH_CODE !== 'undefined' && NG_AUTH_FORCE_AUTH_CODE == "true" && !(code) && !$window.auth.isLoggedIn){
                        let state = btoa(Math.random().toString()).substring(10,15);
                        var authUrl = $window.auth.getAuthorizationURL(state);
                        let eaidString = "";

                        // Forward tracking
                        if(eaid) {
                            eaidString = "#eaid="+eaid
                        }

                        var createAccount = false;
                        if ($stateParams.create_account){
                            createAccount = true;
                        }

                        const locale = Locales.selected || 'en_GB'

                        // If we have create_account in the url, force the redirect to the signup page
                        if(createAccount){
                            authUrl = authUrl.replace(/\.eventix.io\/\?/g, ".eventix.io/signup?");
                            authUrl = authUrl.replace(/\.openticket.local\/\?/g, ".openticket.local/signup?");
                        }

                        let redirectUrl = authUrl + `&locale=${locale}`;
                        if ($stateParams.autoselect_dashboard) {
                            redirectUrl += `&autoselect_dashboard=${$stateParams.autoselect_dashboard}`;
                        }
                        redirectUrl += eaidString

                        console.debug('Redirecting to:', redirectUrl);

                        window.location = redirectUrl;
                        return;
                    }

                    if (code && !$window.auth.isLoggedIn) {
                        return $window.auth.authorizeWithCode(code).then(() => {
                            $stateParams.code = null;
                            return User.getMe().catch(e => {
                                // Make sure to catch unexpected things with tokens...
                                // A 404 in user/me indicates token invalid
                                if(e.status && (e.status == 401 ||  e.status == 404)){
                                    $window.auth.logout();
                                }
                                return $q.reject(e);
                            })
                        }) ;
                    }
                    else {
                        return User.getMe().catch(e => {
                            // Make sure to catch unexpected things with tokens...
                            // A 404 in user/me indicates token invalid
                            if(e.status && (e.status == 401 || e.status == 404)){
                                $window.auth.logout();
                            }
                            return $q.reject(e);
                        })
                    }
                },
                company: function ($q, $stateParams, $state, $transition$, $location, $analytics, $window, $timeout, Company, user, UIMessages) {

                    // If code here still exsist, remove it from the url
                    $location.search('code', null);
                    /**
                     *  Used for resolving the right company, possibly based on the ?as in the url, and
                     *  including the option to update the ?as guid as param in the browser accordingly.
                     */
                    const resolveCompany = (setAs = false, as) => {
                        return $q((resolve, reject) => {

                            Company.getMe().then((company) => {

                                // Make sure to abort this resolve of the app and let it reload with the updated
                                // ?as param to prevent multiple following requests (for the roles of the user)
                                if (setAs) {

                                    $transition$.abort();
                                    reject();

                                    const params = Object.assign({}, $stateParams);

                                    params.as = company.guid;

                                    $timeout(() => {
                                        $state.transitionTo($transition$.to().name, params, { notify: false });
                                    });

                                }

                                $analytics.setUsername(user.guid, { user: user, company: company });

                                resolve(company);

                            }, reject);

                        });

                    };

                    return $q((resolve, reject) => {

                        let asCompanyGuid = $stateParams.as;

                        // Make sure a single (the first) ?as value is used when there are more defined
                        if (Array.isArray(asCompanyGuid)) {
                            asCompanyGuid = asCompanyGuid[0];
                        }

                        // Add the currently used company guid to the ?as if not present
                        if (!asCompanyGuid || !asCompanyGuid.length) {
                            return resolveCompany(true).then(resolve, reject).catch(reject);
                        }

                        // Just resolve the company data if the ?as is already equal to the currently used company
                        if (asCompanyGuid === $window.auth.currentCompanyGuid) {
                            return resolveCompany().then(resolve, reject).catch(reject);
                        }

                        // Try to switch to the company that was set via the ?as and resolve that company
                        return Company.switch(asCompanyGuid).then(res => {
                            if(res  === false){
                                UIMessages.push({
                                    type: 'error',
                                    message: 'Something went wrong switching companies (If the issue persists, please logout and login)',
                                });
                                return $state.go('eventix.dashboard.home');
                            }

                            rudderstack.track('dashboard_company_switch', {
                                switch_to: asCompanyGuid
                            });

                            return resolveCompany(true, asCompanyGuid).then(resolve, reject).catch(reject);

                        }, (error) => {
                            console.log("Error switching");
                            console.log(error);

                            /**
                             *  Make sure to switch back / keep using to the current / last used company when the
                             *  user tries to switch to a company to which he doesn't belong via the ?as. The auth
                             *  SDK just keeps using the current / last used company tokens if the switch fails.
                             */

                            if (
                                !error ||
                                !error.statusCode ||
                                error.statusCode !== 401
                            ) {
                                return reject();
                            }

                            UIMessages.push({
                                type: 'error',
                                message: 'You do not have access to this company',
                            });

                            // Resolves the current / last used company and sets his guid in the ?as
                            return resolveCompany(true).then(resolve, reject).catch(reject);

                        }).catch(reject);

                    });

                },
                roles: function(Role, user, company) {
                    return Role.getUserRolesFromToken(user);
                },
                isAdmin: function(Role, roles, company) {
                    // Not all arguments are used, but this method will wait on resolution of all 'previous' arguments
                    return Role.isAdmin;
                },
                isBeta: function(Role, roles, company) {
                    // Not all arguments are used, but this method will wait on resolution of all 'previous' arguments
                    return Role.isAuthorizedAs('Beta');
                },
                events: function(Event, user, $window, company) {
                    // Not all arguments are used, but this method will wait on resolution of all 'previous' arguments
                    return Event.query();
                },
            },
        });
    })
    .run(function($rootScope, Highlighter, stateChangeErrorService, $state, UIMessages, $window, User,
        $timeout, $transitions, $q, Role, $injector, WhitelabelTemplateOverrides) {

        $transitions.onError({
            to: 'eventix.dashboard.**'
        }, function(trans) {
            let fromState = trans.from();
            let toState = trans.to();
            if(fromState.name === 'eventix.dashboard.error' || toState.name === 'eventix.dashboard.error')
                return $q.resolve();
            let error = trans.error();
            // SUPERSEDED = 2, ABORTED = 3, INVALID = 4, IGNORED = 5, ERROR = 6
            if(error.type === 5 || error.type === 2)
                return $q.resolve();
            stateChangeErrorService(toState, trans.params(), _.get(trans.error(), 'detail'));
            return $state.go('eventix.dashboard.error');
        });

        $transitions.onSuccess({}, function($trans) {
            let params = $trans.params();
            if(params.highlight)
                Highlighter.mark(params.highlight);
            $timeout(() => {
                if ($window.Appcues)
                    $window.Appcues.start();
            }, 500);

            rudderstack.page('dashboard', {
                route_name: $trans.to().name,
                route_from_name: $trans.from().name,
            });
        });

        $transitions.onFinish({
            to: 'eventix.dashboard.**'
        }, function($trans) { // eslint-disable-line consistent-return

            let requiredRoles = _.get($trans.to(), 'data.requiredRoles', []);

            if(requiredRoles.length && !_.find(requiredRoles, role => Role.isAuthorizedAs(role))) {
                $trans.abort();
                UIMessages.push('common.notice.unauthorized');
                return $state.go('eventix.dashboard.home');
            }
        });

        $rootScope.$on('APIMessage', function(event, status, message) {

            if (status >= 400) {
                return UIMessages.push({
                    type: 'danger',
                    message: message
                });
            }

            return UIMessages.push(message);

        });

        $rootScope.$on('logout', function() {
            delete User.cached.me;
        });

    })
    .controller('DashboardController', function($http, $q, $timeout, $filter, $translate, UIMessages, EventixSocket, company, user, Role) {
        var vm = this;
        vm.messages = UIMessages;

        // Make to get the 'correct' current company_id ('as' param)
        let logUser = Object.assign({
            current_company_id: company.guid,
            is_admin:  Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin') // easy filtering them out later
        }, user);
        // Init RS with current user
        rudderstack.identify(user.guid, logUser);

        let orderPayedSubscriptionKey = EventixSocket.subscribe('order.payed', function(data) {
            console.log('Order placed: ', data);

            let message = $translate.instant('common.pubsub.order.payed');

            if (_.isNumber(data.Amount) && data.Amount > 0)
                message += ' [' + $filter('formatCurrency')(data.Amount, company.currency, company.locale) + ']';
            else if (_.isNumber(data.Amount))
                message += ' [free]';

            EventixSocket.playSound('order.payed')
                .then(() => EventixSocket.pushUIMessage(message))
                .catch(angular.noop);
        });

        vm.$onDestroy = function() {
            EventixSocket.unsubscribe('order.payed', orderPayedSubscriptionKey);
        };
    }).name;


/**
 * Copy the value to the Clipboard
 *
 * Attributed to http://stackoverflow.com/a/30810322/7305801
 *
 * @param value
 */
export function copyToClipboard(value) {
    let textArea = document.createElement('textarea');

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = '0';

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';
    textArea.value = value;

    document.body.appendChild(textArea);
    textArea.select();

    let msg = '';
    try {
        let successful = document.execCommand('copy');
        msg = successful ? 'Copied!' : 'common.notice.error';
    } catch (err) {
        msg = 'common.notice.error';
    }

    document.body.removeChild(textArea);
    return msg;
}

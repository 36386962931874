import template from './marketplace.html';
import './marketplace.less';
import { get } from 'scriptjs';

export default angular.module('eventix.dashboard.marketplace',[])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.marketplace', {
            url: '/marketplace',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'MarketplaceController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{}
        });
    })
    .controller('MarketplaceController', function($state, CrudCtrl, $scope, Auth, Role, $sce, $window, Locales) {
        const vm = this;

        const accessToken = $window.auth ? $window.auth.getAccessToken() : null;

        vm.trustSrc = trustSrc;
        vm.wlShowMarketPlace = SHOW_MARKETPLACE;
        vm.isAdmin = Role.isAuthorizedAs('Admin');

        if(!vm.wlShowMarketPlace && !vm.isAdmin){
            return $state.go('eventix.dashboard.home');
        }

        get("https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.6.3/iframeResizer.min.js", () => {
            iFrameResize({'heightCalculationMethod': 'lowestElement'}, '#Marktplace');
        });

        vm.$onInit = function() {
            if (!accessToken || !accessToken.access_token || !accessToken.access_token.length) {
                throw Error('Marketplace should be initialized with access token');
            }

            vm.frameUrl = 'https://weeztix.com/app_library/iframe?locale=' + Locales.selected + '&token=' + accessToken.access_token + '&company_id=' + $window.auth.currentCompanyGuid;
        };

        function trustSrc(src) {
            return $sce.trustAsResourceUrl(src);
        }
    }).name;


import templateUrl from './tickets.html';
import attachShopsModalTemplate from './attachShopsModal.html';
import { copyToClipboard } from '../../../dashboard';

export default angular.module('eventix.dashboard.wizard.simple.tickets', [])
    .config(ModuleConfig)
    .component('wizardSimpleTickets', {
        controller: WizardSimpleTicketsController,
        templateUrl: templateUrl,
        bindings: {
            event: '<',
            company: '<',
            isAdmin: '<',
            shops: '=',
            scanners: '='
        },
        transclude: true,
        restrict: 'A'
    })
    .component('attachShopsModal', {
        controller: AttachShopsModalController,
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        },
        templateUrl: attachShopsModalTemplate
    }).name;

function ModuleConfig($stateProvider) {
    $stateProvider.state('eventix.dashboard.wizard.simple.tickets', {
        url: '/tickets',
        component: 'wizardSimpleTickets',
        bindings: {
            event: 'currentEvent',
            company: 'company',
            isAdmin: 'isAdmin'
        }
    });
}

function WizardSimpleTicketsController($q, $translate, $filter, $uibModal, CrudCtrl, ErrorRejector, TemplateModal, Vat, Company, Role, Ticket, Product) {
    const $ctrl = this;

    // TODO Add required products/product groups for admin

    $ctrl.isCompanyAdmin = Role.isAuthorizedAs('Company Admin');
    $ctrl.hasContract = Role.isAuthorizedAs('Has Contract');
    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

    $ctrl.customSave = customSave;
    $ctrl.openTemplateModal = openTemplateModal;
    $ctrl.openKickbacksModal = openKickbacksModal;
    $ctrl.copyToClipboard = copyToClipboard;

    $ctrl.public = true;

    $ctrl.formOptionsAvailable = [{
        key: 'increment',
        label: $translate.instant('models.ticket.increment')
    }, {
        key: 'availableDates',
        label: $translate.instant('models.ticket.available_from')
    }, {
        key: 'minOrderQuantity',
        label: $translate.instant('models.ticket.min_orderable_amount_per_order')
    }, {
        key: 'maxOrderQuantity',
        label: $translate.instant('models.ticket.max_orderable_amount_per_order')
    }, {
        key: 'availabilityMargin',
        label: $translate.instant('models.ticket.availability_margin')
    }, {
        key: 'shops',
        label: $translate.instant('models.ticket.shops')
    }, {
        key: 'metaData',
        label: $translate.instant('models.ticket.meta_data')
    }, {
        key: 'hideWithoutCoupon',
        label: $translate.instant('models.ticket.hideWithoutCoupon')
    }
    ];

    if ($ctrl.isAdminOrWLAdmin) {
        $ctrl.formOptionsAvailable.push({
            key: 'seats_category_key',
            label: $translate.instant('models.ticket.seats_category_key')
        });
        $ctrl.formOptionsAvailable.push({
            key: 'late_personalization',
            label: $translate.instant('models.ticket.late_personalization')
        });
        $ctrl.formOptionsAvailable.push({
            key: 'barcode_type',
            label: $translate.instant('models.ticket.barcode_type')
        });
        $ctrl.formOptionsAvailable.push({
            key: 'class',
            label: $translate.instant('models.ticket.class')
        });
        $ctrl.formOptionsAvailable.push({
            key: 'combines_products',
            label: $translate.instant('models.ticket.combinesProducts')
        });
    }

    $ctrl.$postLink = function() {

        setupCrud();

        if ($ctrl.isAdminOrWLAdmin || ($ctrl.isCompanyAdmin && $ctrl.hasContract)) {
            $ctrl.formOptionsAvailable.push({
                key: 'serviceCost',
                label: $filter('capitalize')($translate.instant('models.kickback.fee'))
            });
        }

        if ($ctrl.isAdminOrWLAdmin) {
            $ctrl.formOptionsAvailable.push({
                key: 'swappable',
                label: $filter('capitalize')($translate.instant('models.ticket.swappable'))
            });
        }

        if (!$ctrl.formOptions || $ctrl.formOptions.availableDates === undefined) {
            _.set($ctrl, 'formOptions.availableDates', true);
        }
    };

    /**
     * Setup CrudCtrl
     */
    function setupCrud() {
        $ctrl.crud = new CrudCtrl(getTickets, newTicket);
        $ctrl.crud.beforeNew(guessPublic);
        $ctrl.crud.beforeNew(getEventDates);
        // $ctrl.crud.beforeEdit(getEventDates);
        // $ctrl.crud.beforeNew(ticket => ticket.ticket.$errors.clear());
        // $ctrl.crud.beforeEdit(ticket => ticket.ticket.$errors.clear());
        $ctrl.crud.addBelongsToMany('metaData', 'MetaData');
        $ctrl.crud.addBelongsToMany('shops', 'Shop');
        $ctrl.crud.addBelongsToMany('products', 'Product');
        $ctrl.crud.addBelongsToMany('eventDate', 'EventDate');
        $ctrl.crud.addBelongsToMany('groups', 'ProductGroup');

        $ctrl.crud.beforeEdit(guessPublic);
        $ctrl.crud.beforeEdit(preloadProductGroupChildren);
        // DD-FE-272B attaching all event dates of the event to the newly created ticket.
        $ctrl.crud.afterSave(CrudCtrl.onlyNewHook(attachEventDates));
        $ctrl.crud.afterSave(CrudCtrl.onlyNewHook(attachToShops));
        $ctrl.crud.afterSave(CrudCtrl.onlyNewHook(getTicketProducts));
        $ctrl.crud.afterSave(saveProductGroups);
    }

    /**
     * Get all event tickets
     *
     * @return {Promise<Array>} Resolves with an array of Tickets
     */
    function getTickets() {
        return $ctrl.event.$queryTicket()
    }

    $ctrl.getTicketPrice = getTicketPrice;

    function getTicketPrice(ticket) {
        if (!ticket || !ticket.calculateMinimumPrice) {
            console.error('Cannot get ticket price, not properly initialized.');

            return -999999;
        }

        if (_.has(ticket, 'minimumPrice')) {
            return ticket.minimumPrice;
        }

        if (!ticket.calculating) {
            ticket.calculating = true;
            ticket.calculateMinimumPrice(false);
        }

        return '...';
    }

    /**
     * Create a (default) new Ticket
     *
     * @return {Promise<Array>} Resolves with a Ticket instance
     */
    function newTicket() {
        return Ticket.new({
            increment: 1,
            status_overrule: 'auto',
            available_stock: 0,
            availability_margin: 0,
            min_orderable_amount_per_order: 1,
            max_orderable_amount_per_order: 20,
            percentage_service_costs_in_ticket: 0,
            min_price: 0,
            vat_percentage: Vat.getDefaultRateFor(_.get(Company.cached, [Company.getCurrentId(), 'country'], 'NL')),
            combines_products: true,
            swappable: true,
        });
    }

    /**
     * Wrapper for the default crud save operation to allow for injecting a confirmation modal
     * The modal shows in two circumstances:
     * 1. The ticket is publicly available and no shops were selected -> Cancel/No/Yes modal
     * 2. Shops were selected for the ticket -> Cancel/Ok modal
     * A distinction was made due to interactively adding shops to the ticket,
     * in which case a 'No' option would not make sense.
     *
     * @returns {Promise<void>} An promise that resolves after a crud save or
     * after the save was cancelled through the modal
     */
    function customSave() {
        let ticket = $ctrl.crud.model;
        let hasChosenShops = !_.isEmpty(ticket.shops);

        if ($ctrl.crud.showNew() && ($ctrl.public || hasChosenShops)) {
            // Wrap in object for passing reference
            $ctrl.toBeAdded = {
                shops: hasChosenShops ? ticket.shops : $ctrl.shops
            };

            return $uibModal.open({
                component: 'attachShopsModal',
                resolve: {
                    toBeAdded: () => $ctrl.toBeAdded,
                    showRejection: () => $ctrl.public && !hasChosenShops
                }
            }).result
                .then(() => $ctrl.event.$crudCreateOrSaveChild($ctrl.crud, 'Ticket'))
                .catch(() => $q.resolve());
        }

        return $ctrl.event.$crudCreateOrSaveChild($ctrl.crud, 'Ticket');
    }

    /**
     * Determine whether the ticket is publicly available
     *
     * @returns {Boolean} Whether or not the ticket is publicly available
     */
    function guessPublic(ticket) {

        $ctrl.public = ticket.guid ? !_.isEmpty(ticket.belongsToMany.Shop || []) : true;

        return $ctrl.public;

    }

    /**
     * Used for preloading the children (prop) of the product groups.
     *
     * @returns {Promise} Resolves when done preloading
     */
    function preloadProductGroupChildren(ticket) {

        let promises = [
            ticket.$queryProductGroup(),
            ticket.$queryProduct(),
            ticket.$queryShop(),
            ticket.fillProducts()
        ];

        return $q.all(promises)
            .then(() => {
                guessPublic(ticket);

                let productGroupPromises = _.map(ticket.groups, group => {
                    return $q.all(_.map(group.products, productId => Product.get({guid: productId})))
                        .then(products => group.children = products);
                });

                return $q.all(productGroupPromises)
                    .then(() => ticket);

            });

    }

    /**
     * Retrieve EventDates from Event
     *
     * @returns {Promise} Resolves when done
     */
    function getEventDates() {
        // Retrieves and caches the eventdates of an event (To speed up save)
        return $ctrl.event.$queryEventDate();
    }

    /**
     * Retrieve products from newly created Ticket
     *
     * @param {Any} [err=null] Error from saving
     * @param {Ticket} ticket Instance that was saved
     * @param {Boolean} isNew Whether the instance was just created
     * @returns {Promise} Resolves when done
     */
    function getTicketProducts(err, ticket, isNew) {
        console.log('getProducts', 'Retrieving products!', err, ticket, isNew);

        return ticket.$queryProduct()
            .then(products => {
                return products;
            })
            .catch(error => {
                console.error('getProducts', 'Failed to retrieve products', error);

                return $q.reject(error);
            });
    }

    /**
     * Attach selected event dates to a newly created ticket
     * Only runs on new tickets
     * DD-FE-272B attaching all event dates of the event to the newly created ticket.
     *
     * @param {Any} [err=null] Error from saving
     * @param {Ticket} ticket Instance that was saved
     * @param {Boolean} isNew Whether the instance was just created
     * @returns {Promise} Resolves when done
     */
    function attachEventDates(err, ticket, isNew) {
        console.log('attachEventDates', 'Retrieving eventDates!', err, ticket, isNew);

        return $ctrl.event.$queryEventDate(true)
            .then(eventDates => {
                if (_.isEmpty(eventDates)) {
                    return $q.reject('No event dates found');
                }

                console.log('attachEventDates', 'GO', eventDates);

                return _.reduce(
                    eventDates,
                    (promise, eventDate) => {
                        console.log('attaching event date', eventDate);

                        return promise.then(() => ticket.$attachEventDate(eventDate));
                    },
                    $q.resolve()
                );
            })
            .catch(error => {
                console.error('attachEventDates', 'Failed to attach Event Date', error);

                return $q.reject(error);
            });
    }

    /**
     * Attach newly created ticket to scanners
     *
     * Only runs on new tickets
     *
     * @param {Any} [err=null] Error from saving
     * @param {Ticket} ticket Instance that was saved
     * @param {Boolean} isNew Whether the instance was just created
     * @returns {Promise} Resolves when done
     */
    function attachToScanners(err, ticket, isNew) {
        // Attached the cached products of a ticket to the event scanner(s)
        console.log('attachToScanners', 'Attaching products!', err, ticket, isNew);

        return ticket.$queryProduct(true)
            .then(products => _.reduce(
                $ctrl.scanners,
                (promise, scanner) => promise.then(() => {
                    return _.reduce(
                        products,
                        (promise, product) => promise.then(() => {
                            return scanner.$attachProduct(product)
                        }),
                        $q.resolve()
                    );
                }),
                $q.resolve()
            ))
            .catch(error => {
                console.error('attachToScanners', 'Failed to attach Products to Scanners', error);

                return $q.reject(error);
            });
    }

    /**
     * Attach newly created ticket to shops if its public
     *
     * Only runs on new tickets, existing tickets use togglePublic
     *
     * @param {Any} [err=null] Error from saving
     * @param {Ticket} ticket Instance that was saved
     * @param {Boolean} isNew Whether the instance was just created
     * @returns {Promise} Resolves when done
     */
    function attachToShops(err, ticket, isNew) {
        if (!$ctrl.public || !_.isEmpty(ticket.shops)) {
            console.log('attachToShops', 'Not public, or shops already attached');

            return $q.resolve();
        }

        return _.reduce(
            $ctrl.toBeAdded.shops,
            (promise, shop) => promise.then(() => ticket.$attachShop(shop)),
            $q.resolve()
        );
    }

    /**
     * Save product groups attached to this ticket
     * Note: This is the simple event flow, in theory there should not be product groups
     *
     * @param {Any} err An error that occured while saving
     * @param {Ticket} ticket Instance of SimpleTicket
     * @param {Boolean} isNew Whether its a new ticket
     * @return {Promise<Array<ProductGroup>>} Resolves with product groups
     */
    function saveProductGroups(err, ticket, isNew) {
        if (err) {
            console.error('saveProductGroups', 'Previous action failed, ignore', err)

            return $q.reject(err);
        }

        console.log('saveProductGroups', 'Saving product groups', ticket.groups);

        return $q.all(_.map(ticket.groups, group => {
            if (group.$dirty || !group.guid) {
                group.ticket_id = ticket.guid;

                console.log('saveProductGroups', 'Saving product group', group);

                return group.$save();
            }

            console.log('saveProductGroups', 'Not saving product group', group);

            return $q.resolve(group);
        }));
    }

    function openKickbacksModal(ticket) {
        let company = $ctrl.company;

        return $uibModal.open({
            size: 'lg',
            controller: function() {
                const $ctrl = this;

                $ctrl.$onInit = function() {
                    $ctrl.ticket = ticket;
                    $ctrl.company = company;
                };
            },
            controllerAs: '$ctrl',
            template: '<div class="modal-body"><kickbacks applicable-model="$ctrl.ticket" applicable-company="$ctrl.company" applied-type="Ticket"></kickbacks></div><div class="modal-footer"><a class="btn btn-default" ng-click="$close()">Close</a></div>'
        });
    }

    function openTemplateModal(ticket) {
        return TemplateModal.open('ticket', ticket);
    }
}

function AttachShopsModalController() {
    const $ctrl = this;

    $ctrl.$onInit = function() {
        _.assign($ctrl, $ctrl.resolve);
    };

    $ctrl.nosave = function() {
        $ctrl.toBeAdded.shops = [];
        $ctrl.close();
    };
}

import templateUrl from './template.html';
import ngFileUpload from 'angular-file-upload';
import './template.less';

export default angular.module('eventix.dashboard.template', ['angularFileUpload'])
/**
 * @ngdoc service
 * @name eventix.dashboard.template.TemplateModal
 * @description
 * A helper for opening a ticket template modal
 */
    .factory('TemplateModal', function($uibModal, $q) {
        return {
            /**
             * @ngdoc method
             * @name eventix.dashboard.template.TemplateModal#open
             * @methodOf eventix.dashboard.template.TemplateModal
             * @description
             * Open a template modal to manage the current or a new template.
             *
             * @param {String} ownerType The model type to manage template(s) for
             * @param {Whitelabel|Company|Event|Ticket} ownerModel The model to manage template(s) for
             * @param {Object} options Collection of options for the template modal
             *
             * @return {Promise<Object>} The modal instance containing promises and callbacks. See node_modules/angular-ui-bootstrap/src/modal/modal.js
             */
            open: function(ownerType, ownerModel, options) {
                if (!['whitelabel', 'company', 'event', 'ticket'].includes(ownerType)) {
                    return $q.reject('TemplateModal: Not a valid owner type');
                }

                // TODO endpoints don't exist yet, so this is probably for the new dashboard at some point...
                if (['whitelabel'].includes(ownerType)) {
                    return $q.reject('TemplateModal: Owner type not implemented (yet)');
                }

                if (_.isNil(ownerModel)) {
                    return $q.reject('TemplateModal: Not a valid owner type');
                }

                options = _.assign({
                    size: 'lg',
                    maxBytes: 1.5 * 1024 * 1024
                }, options);

                const resolves = {
                    ownerType: () => ownerType,
                    ownerModel: () => ownerModel,
                    options: () => options
                };

                return $q.resolve($uibModal.open({
                    size: options.size,
                    component: 'templateModal',
                    resolve: resolves
                }));
            }
        };
    })
    .component('templateModal', {
        controller: TemplateModalController,
        templateUrl: templateUrl,
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        }
    })
    .name;

function TemplateModalController($scope, $http, $q, Locales, FileUploader, ErrorRejector, ConfirmDeleteSweet, Company, Template, $window) {
    const $ctrl = this;

    const accessToken = $window.auth ? $window.auth.getAccessToken() : null;

    $ctrl.unlink = unlink;

    $ctrl.busy = true;

    $ctrl.locales = Locales;

    $ctrl.ownerType = null;
    $ctrl.ownerModel = null;
    $ctrl.options = {};

    $ctrl.settings = {};

    $ctrl.template = null;

    const headers = {
        Authorization: accessToken ? accessToken.access_token : null,
    }

    if ($window.auth && $window.auth.currentCompanyGuid && $window.auth.currentCompanyGuid.length) {
        headers.Company = $window.auth.currentCompanyGuid;
    }

    $ctrl.upload = {
        uploader: new FileUploader({
            headers,
            removeAfterUpload: true,
        }),
        fileSize: 0,
        error: null,
        progress: 0,
    };

    $ctrl.upload.uploader.onAfterAddingFile = uploadBackground;

    $ctrl.exampleBackgroundLink = BLANK_TICKET_TEMPLATE;
    $ctrl.showGuideText = (TICKET_TEMPLATE_GUIDE).toString() === 'true';

    $ctrl.$onInit = function() {
        if (_.isNil($ctrl.resolve)) {
            throw Error('TemplateModalController should be initialized with resolve binding');
        }

        if (!accessToken || !accessToken.access_token || !accessToken.access_token.length) {
            throw Error('TemplateModalController should be initialized with access token');
        }

        _.assign($ctrl, $ctrl.resolve);

        parseSettings();

        registerLocaleWatcher();

        getTemplate()
            .then(getExample);
    };

    function parseSettings() {
        $ctrl.settings = {
            name: _.get($ctrl.ownerModel, 'name', 'Default ticket template')
        };

        switch ($ctrl.ownerType) {
        case 'whitelabel':
            $ctrl.settings.companyId = _.get($ctrl.ownerModel, 'owning_company');
            $ctrl.settings.locale = _.get($ctrl.ownerModel, 'locale', 'en_GB');
            $ctrl.settings.name = 'Whitelabel default for ' + $ctrl.settings.name;
            // $ctrl.settings.url = `/whitelabel/${_.get($ctrl.ownerModel, 'guid')}/template/ticket`; // TODO
            break;
        case 'company':
            $ctrl.settings.companyId = _.get($ctrl.ownerModel, 'guid');
            $ctrl.settings.locale = _.get($ctrl.ownerModel, 'locale', 'en_GB');
            $ctrl.settings.name = 'Company default for ' + $ctrl.settings.name;
            $ctrl.settings.url = `/company/${_.get($ctrl.ownerModel, 'guid')}/template/ticket`;
            break;
        case 'event':
            $ctrl.settings.companyId = _.get($ctrl.ownerModel, 'company_id');
            $ctrl.settings.locale = _.get($ctrl.ownerModel, 'locale', 'en_GB');
            $ctrl.settings.name = 'Event default for ' + $ctrl.settings.name;
            $ctrl.settings.url = `/event/${_.get($ctrl.ownerModel, 'guid')}/template/ticket`;
            break;
        case 'ticket':
            $ctrl.settings.companyId = _.get($ctrl.ownerModel, 'company_id');
            $ctrl.settings.locale = _.get(Company.cached, [$ctrl.settings.companyId, 'locale'], 'en_GB');
            // No need to change the name...
            $ctrl.settings.url = `/ticket/${_.get($ctrl.ownerModel, 'guid')}/template/ticket`;
            break;
        default:
            throw new Error('Invalid owner type');
        }

        return $ctrl.settings;
    }

    function registerLocaleWatcher() {
        $scope.$watch('$ctrl.settings.locale', locale => {
            if (locale && $ctrl.template && $ctrl.template.template) {
                $ctrl.template.template
                    .getVariant(locale)
                    .then(getExample);
            }
        });
    }

    function getTemplate() {
        $ctrl.busy = true;

        return getNearestTemplate()
            .catch(error => {
                $ctrl.template = null;

                return $q.reject(error);
            })
            .finally(() => $ctrl.busy = false);
    }

    function getNearestTemplate() {
        return $http.get($ctrl.settings.url)
            .then(result => result.data)
            .then(template => {
                $ctrl.template = {
                    link: template.template_link,
                    isLinkedToCurrentModel: template.template_link.owned_type === $ctrl.ownerType && template.template_link.owned_id === _.get($ctrl.ownerModel, 'guid', false),
                    preview: null
                };

                _.unset(template, 'template_link');

                $ctrl.template.template = new Template(template);

                return $ctrl.template;
            })
            .then(template => template.template.getVariant($ctrl.settings.locale).then(() => template))
            .catch(ErrorRejector.handle);
    }

    function getExample() {
        if (!$ctrl.template.template) {
            return $q.reject('No template to fetch example for');
        }

        $ctrl.busy = true;

        return $ctrl.template.template.getExample($ctrl.settings.locale).then(dataBase64 => {
            $ctrl.busy = false;

            return $ctrl.template.preview = 'data:image/jpeg;base64,' + dataBase64;
        });
    }

    function uploadBackground(file) {
        if ($ctrl.busy) {
            return file.remove();
        }

        $ctrl.upload.fileSize = _.get(file, 'file.size', 0);

        if ($ctrl.upload.fileSize > $ctrl.options.maxBytes) {
            return $ctrl.upload.fileSize;
        }

        $ctrl.busy = true;
        $ctrl.upload.error = null;
        $ctrl.upload.progress = 1;

        file.alias = 'background';

        let url = '//' + _.trim(DASHBOARD_API_ROOT, '/').replace(/^(?:https?:)?\/\//, '');
        url += '/' + _.trim($ctrl.settings.url, '/');
        url += '/' + _.trim($ctrl.settings.locale, '/');

        file.url = url;

        file.onProgress = p => $ctrl.upload.progress = p;
        file.onSuccess = result => {
            getTemplate()
                .then(getExample);
        };
        file.onError = res => {
            $ctrl.upload.error = res;
            $ctrl.busy = false;
        };
        file.onComplete = () => {
            $ctrl.upload.progress = 0;
        };

        return file.upload();
    }

    function unlink() {
        let translations = {
            text: {translation: 'models.ticket_template.notice.deleteConfirm'},
            confirm: 'common.action.delete'
        };

        return ConfirmDeleteSweet.open($ctrl.template.template.name, translations)
            .then(() => $http.delete($ctrl.settings.url))
            .then(() => {
                getTemplate().then(getExample);
            })
            .catch(ErrorRejector.handle);
    }
}

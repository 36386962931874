export default angular.module('eventix.dashboard.import.orderImporter',[])
    .factory('OrderImporter', function(LibraryLoader, $window, $q, Locales, $http, $state) {
        let papaParse = `/js/papaparse.js`;
        let Papa;
        let instance;

        let loaded = LibraryLoader.load(papaParse).then(() => {
            Papa = instance.$Papa = $window.Papa;
            Papa.SCRIPT_PATH = papaParse;
            instance.$loaded = true;
            if(!Papa)
                throw new Error('CSV parser not found');
        });

        /**
         * @ngdoc service
         * @name eventix.dashboard.import.OrderImporter
         * @description
         * Used to create orders outside of a shop
         *
         * Uses the PapaParse library that is loaded from CDNJS.
         */
        function OrderImporter() {}

        /**
         * @ngdoc method
         * @name eventix.dashboard.import.OrderImporter#toCSV
         * @methodOf eventix.dashboard.import.OrderImporter
         * @description
         * Convert array of orders to CSV
         *
         * @param {Array<Object>} orders An array of orders to import
         * @return {Promise<String>} Resolves with a CSV string
         */
        OrderImporter.prototype.toCSV = function(orders) {
            return loaded.then(() => {
                let uniqOrders = [];
                _.forEach(orders, order => {
                    order = _.omit(order, ['$$hashKey']);
                    let dupe = _.find(uniqOrders, o => _.isEqual(o, order));
                    if(!dupe)
                        uniqOrders.push(order);
                    else
                        dupe['order.order_tickets.number_of_tickets']++;
                });
                return Papa.unparse(uniqOrders, {
                    quotes: false,
                    quoteChar: '"',
                    delimiter: ',',
                    newline: '\r\n',
                    header: true
                });
            });
        };

        /**
         * @ngdoc method
         * @name eventix.dashboard.import.OrderImporter#toJSON
         * @methodOf eventix.dashboard.import.OrderImporter
         * @description
         * Convert CSV to an array of orders (if you want to correct an error in the CSV)
         *
         * @param {String|File} csv CSV (string) with orders
         * @return {Promise<Array>} Resolves with an array of orders
         */
        OrderImporter.prototype.toJSON = function(csv) {
            return loaded.then(() => {
                var deferred = $q.defer();
                Papa.parse(csv, {
                    header: true,
                    worker: true,
                    complete: function(csv) {
                        deferred.resolve(csv);
                    },
                    error: function(err) {
                        deferred.reject(err);
                    }
                });
                return deferred.promise;
            });
        };

        /**
         * @ngdoc method
         * @name eventix.dashboard.import.OrderImporter#validate
         * @methodOf eventix.dashboard.import.OrderImporter
         * @description
         * Convert array of orders to CSV
         *
         * @param {Array|String|File} orders A CSV (file|string) or an array of orders to import
         * @return {Promise<Any>} Rejects with error messages if there are any, or resolves with a CSV string
         */
        OrderImporter.prototype.validate = function(orders) {
            let promise = $q.resolve(orders);
            if(orders instanceof Array)
                promise = this.toCSV(orders);
            if(orders instanceof File) {
                let deferred = $q.defer();
                promise = deferred.promise;
                let reader = new FileReader();
                reader.onload = function(event) { deferred.resolve(event.target.result); };
                reader.onerror = function(event) { deferred.reject(event.target.error); };
                reader.onprogress = function(evt) {
                    if (evt.lengthComputable) {
                        var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
                        deferred.notify(percentLoaded);
                    }
                };
                reader.readAsText(orders);
            }
            return promise.then(csv => {
                return $http.post('/import/validate', { csv_string: csv })
                    .then(res => $q.resolve({ status: 'valid' }))
                    .catch(res => {
                        if(res.status === 406)
                            return $q.resolve({ status: 'invalid', invalid: res.data.error_description.invalid });
                        if(res.status >= 400)
                            return $q.reject(res.data);
                        return $q.reject(res.statusText);
                    });
            });
        };

        /**
         * @ngdoc method
         * @name eventix.dashboard.import.OrderImporter#process
         * @methodOf eventix.dashboard.import.OrderImporter
         * @description
         * Convert array of orders to CSV
         *
         * @param {Array|String} orders A CSV or an array of orders to import
         * @param {Boolean} [renderPdf=true] Should a ticket PDF be rendered
         * @param {Boolean} [sendEmail=true] Should the ticket PDF be sent to receiver email
         * @return {Promise<Any>} Rejects with error messages if there are any
         */
        OrderImporter.prototype.process = function(orders, renderPdf = true, sendEmail = true) {
            let promise = $q.resolve(orders);
            if(orders instanceof Array)
                promise = this.toCSV(orders);
            if(orders instanceof File) {
                let deferred = $q.defer();
                promise = deferred.promise;
                let reader = new FileReader();
                reader.onload = function(event) { deferred.resolve(event.target.result); };
                reader.onerror = function(event) { deferred.reject(event.target.error); };
                reader.onprogress = function(evt) {
                    if (evt.lengthComputable) {
                        var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
                        promise.notify(percentLoaded);
                    }
                };
                reader.readAsText(orders);
            }
            return promise.then(csv => {
                let params = {
                    csv_string: csv,
                    render_pdf: renderPdf ? 1 : 0,
                    send_email: sendEmail ? 1 : 0
                };
                return $http.post('/import/process', params)
                    .then(res => res.data)
                    .catch(res => {
                        if(res.status >= 400)
                            return $q.reject(res.data);
                        return $q.reject(res.statusText);
                    });
            });
        };

        /**
         * @ngdoc method
         * @name eventix.dashboard.import.OrderImporter#newOrders
         * @methodOf eventix.dashboard.import.OrderImporter
         * @description
         * Generate an array of orders to import. These are (almost) empty, flat objects with correct keys set according
         * to the import API format.
         *
         * @param {Shop} shop A shop in which to import
         * @param {Arary<Ticket>|Ticket} tickets An array of tickets or a single ticket
         * @param {Number|Object<guid,number>} amounts Amounts of tickets to generate
         * @return {Promise<Array>} Resolves with an array of orders
         */
        OrderImporter.prototype.newOrders = function(shop, tickets, amounts = 1) {
            if(!angular.isArray(tickets))
                tickets = _.compact([tickets]);
            if(!shop || _.isEmpty(tickets))
                return $q.reject(new Error('Invalid shop or ticket(s) selected'));
            if(angular.isNumber(amounts)) {
                let ticketGuids = _.map(tickets, 'guid');
                amounts = _.fill(Array(ticketGuids.length), amounts);
                amounts = _.zipObject(ticketGuids, amounts);
            } else {
                let missingAmount = _.find(tickets, ticket => _.isNil(amounts[ticket.guid]));
                if(missingAmount)
                    return $q.reject(new Error('Missing ticket amounts: ' + missingAmount.guid));
                let invalidAmount = _.findKey(amounts, v => !(_.isNumber(v) && v >= 0));
                if(invalidAmount)
                    return $q.reject(new Error('Invalid ticket amounts: ' + invalidAmount));
            }

            let shopMetaData = null;
            let ticketMetaData = null;
            let shopPromise = shop.$queryMetaData().then(shopMeta => shopMetaData = shopMeta);
            let ticketPromise = $q.all(
                _.map(
                    tickets,
                    ticket => ticket.$queryMetaData().then(ticketMeta => [ticket.guid, ticketMeta])
                )
            ).then(results => ticketMetaData = _.fromPairs(results));

            return $q.all([ticketPromise, shopPromise]).then(() => {
                let shopMetaDataValues = _.fromPairs(
                    _.map(
                        shopMetaData,
                        meta => ['order.metadata.' + meta.guid, getDefaultValue(meta)]
                    )
                );
                return _.flatMap(tickets, ticket => {
                    let ticketMetaDataValues = _.fromPairs(
                        _.map(
                            ticketMetaData[ticket.guid],
                            meta => ['order.order_tickets.metadata.' + meta.guid, getDefaultValue(meta)]
                        )
                    );
                    return _.times(amounts[ticket.guid], function() {
                        let order = {
                            'order.shop_id': shop.guid,
                            'order.firstName': '',
                            'order.lastName': '',
                            'order.email': '',
                            'order.locale': Locales.selected,
                            'order.order_tickets.ticket_id': ticket.guid,
                            'order.order_tickets.number_of_tickets': 1
                        };
                        _.assign(order, shopMetaDataValues);
                        _.assign(order, ticketMetaDataValues);
                        return order;
                    });
                });
            });
        };

        instance = new OrderImporter();
        return instance;
    }).name;

function getDefaultValue(meta) {
    var value;
    switch(meta.type) {
        case 'integer':
            value =  meta.default || '';
            break;
        case 'date':
            value = meta.default || '';
            break;
        case 'boolean':
            value = meta.default || '';
            break;
        default:
            value = meta.defalut || '';
            break;
    }
    return value;
}
